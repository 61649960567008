import React, {useRef, useState} from 'react'
import SimpleReactValidator from 'simple-react-validator';
import emailjs from "@emailjs/browser";
import {toast} from "react-toastify";
import './style.css';

const RSVPFrom = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        let templateId = "template_u54az1d";

        if (forms.participation === 'notParticipe') {
            templateId = "template_rbjmpit";
        }

        emailjs.sendForm("service_wuij1ci", templateId, form.current, "WxZWdGv7Jvgqr4Wer").then(
            (result) => {
                toast.success("Merci beaucoup, votre message a bien été envoyé !", {
                    position: "bottom-center", // Position de la notification
                    autoClose: false,       // Durée d'affichage en millisecondes (5 secondes dans cet exemple)
                    hideProgressBar: false, // Afficher la barre de progression
                });
            },
            (error) => {
                toast.success("Aïe. Une erreur est survenue ! Votre message n'a pas pû être envoyé...", {
                    position: "bottom-center", // Position de la notification
                    autoClose: false,       // Durée d'affichage en millisecondes (5 secondes dans cet exemple)
                    hideProgressBar: false, // Afficher la barre de progression
                });
            }
        );
    };

    const [forms, setForms] = useState({
        nom: '',
        email: '',
        nombreAdulte: '',
        commentaire: '',
        participation: 'participe'
    });
    const [validator] = useState(new SimpleReactValidator({
        messages: {
            required: 'Le champ :attribute est requis.',
            email: 'L\'email doit être valide.',
        },
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({...forms, [e.target.name]: e.target.value})
        if (validator.allValid() || forms.participation === 'notParticipe') {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const handleParticipationChange = (e) => {
        setForms({
            participation: e.target.id
        });
        validator.hideMessages();
    }

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid() || forms.participation === 'notParticipe') {
            sendEmail(e);
            validator.hideMessages();
            setForms({
                nom: '',
                email: '',
                nombreAdulte: '',
                commentaire: '',
                participation: 'participe'
            });
        } else {
            validator.showMessages();
        }
    };

    return (
        <form ref={form} onSubmit={(e) => submitHandler(e)} className="contact-validation-active">
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.nom}
                            type="text"
                            name="nom"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Prénom Nom"/>
                        {validator.message('nom', forms.nom, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Email"/>
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input type="radio" id="participe" name="radio-group"
                                   checked={forms.participation === 'participe'}
                                   onChange={(e) => handleParticipationChange(e)}/>
                            <label htmlFor="participe">Oui, je serais là</label>
                        </p>
                        <p>
                            <input type="radio" id="notParticipe" name="radio-group"
                                   checked={forms.participation === 'notParticipe'}
                                   onChange={(e) => handleParticipationChange(e)}/>
                            <label htmlFor="notParticipe">Non, je ne pourrais pas venir</label>
                        </p>
                    </div>
                </div>
                {forms.participation === 'participe' && (
                    <div className="col col-lg-12 col-12">
                        <div className="form-field">
                            <select
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                                value={forms.nombreAdulte}
                                type="text"
                                className="form-control"
                                name="nombreAdulte">
                                <option>Nombre d'adultes</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                            </select>
                            {validator.message('nombreAdulte', forms.nombreAdulte, 'required')}
                        </div>
                    </div>
                )}

                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <textarea
                            value={forms.commentaire}
                            rows={5}
                            name="commentaire"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Commentaire / Questions"/>
                    </div>
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Envoyer</button>
            </div>
        </form>
    )
}

export default RSVPFrom;